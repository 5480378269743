import React from 'react';

export default ()=>(
    <div className="header">
        <div className="container">
            <div className="row">
                <div className="col-md-10 col-md-offset-1">
                    <div className="logo">
                        <a href="/"><img src="/static/img/logo.svg" height="16px"/> Codeflow Packages</a>
                    </div>
                    {/*<div className="nav">
                        <a href="/user/login">Sign In</a>
                        <a href="/user/signup">Sign Up</a>
                        <a href="/docs">Docs</a>
                    </div>*/}
                </div>
            </div>
        </div>
    </div>
)