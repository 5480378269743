import React from "react";
import Api from "../../core/api";
import ModuleList from "./module/list";

export default class PackageView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ns: this.props.match.params.ns,
            name: this.props.match.params.name,
            item: null
        };
    }

    componentDidMount() {
        this.load(this.state.providerId, this.state.serviceType);
    }

    load = async(id, type)=> {
        try {
            let response = await Api.request('package/details', {namespace: `${this.state.ns}/${this.state.name}`, module_details: true, load_docs: true}, 'POST')
            if(!response || !response.data) {
                return this.props.history.push(`/notfound`);
            }
            this.setState({ item: response.data});

            //App.setPage({title: `Service Details`,
            //    allowBack: true
            //});
        } catch(e) {}
    }

    render() {
        let pckg = this.state.item || {}

        return <div className="container package-info">
            <div className="package-info">
                <a className="heading" href="#">
                    <h2>{pckg.namespace}  <small>{pckg.version}</small></h2>
                </a>

                {pckg.description && <p className="description">{pckg.description}</p>}

                {pckg.docs && <div className="package-docs" dangerouslySetInnerHTML={{ __html: pckg.docs}} />}
                {!pckg.docs && <div className="modules">
                    {pckg.modules && <ModuleList modules={pckg.modules} pckg_namespace={pckg.namespace} />}
                </div>}
            </div>
        </div>
    }
}