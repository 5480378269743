import React from 'react';
import Routes from "./Routes";
import Pagefooter from './common/footer';
import Navbar from './common/navbar';
import Searchbar from './common/searchbar';

export default (props) => {
    return <React.Fragment>
                <Navbar />
                <Searchbar />
                <Routes {...props} />
                <Pagefooter />
        </React.Fragment>
}