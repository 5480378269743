

import * as Utils from "./utils";

const VERSION = '0.1.0';
const BUILD_VERSION = 'web';

export class App {

    constructor() {
        this.data = null;
        this.user = null;
        this.config = null;
    }

    /**
     * Initialize the app specific information including the UUID for installed version
     */
    async initialize() {

        let appData = this.data ? {...this.data} : {};

        if(!appData.installed_at || !appData.uuid) {
            appData.installed_at = new Date().getTime();
            appData.version = VERSION;
            appData.build_version = BUILD_VERSION;
            appData.uuid = Utils.generateGUID();

            this.track('install', appData);
        } else if(Utils.versionCheck(this.data.version, appData.version, '>')) {
            appData.updated_at = new Date().getTime();
            appData.last_version = appData.version;
            appData.version = this.data.version;

            this.track('update', appData);
        }

        //require("./config").default.initialize(true, appData.uuid);
        //await require("./user").default.loadSessionUser();
        require("./tracker").default.initialize();



        return appData;
    }

    /**
     * Get the UUID for the app
     * @returns {*}
     */
    getAppUuid() {
        if(this.data && this.data.uuid) {
            return this.data.uuid
        }
        return this.data.uuid;
    }

    isLoggedIn() {
        return this.user && this.user.id;
    }

    handleLogout(event) {
        //return require("./user").default.handleLogout();
    }

    showLoadingTl() {

    }
    hideLoading() {

    }
    errorPopup(title, error) {
        /*<div class="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>title</strong> error
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>*/
    }

    track(action, data) {

    }

    log() {
        let args = [...arguments];
        let types  =['info', 'warn', 'error', 'log', 'dir','debug'];
        let type = args.pop();
        if(args.length === 0 || types.indexOf[type] === -1) {
            type = 'log';
            args.push(type);
        }
        console[type].apply(null, args);
    }
}

export default new App();