import React, { Component } from "react";
import Layout from "./components/Layout";
import CoreApp from "./core/app";
import Tracker from "./core/tracker";
//import Page from "./core/page";

export default class App extends Component {

    constructor(props) {
        super(props);

        //initialize app on borwser after component is mounted
        if(typeof window !== 'undefined') {
            this.state = {initialized: false};
            return;
        }
        this.state = {initialized: true};
    }

    componentDidMount() {
        CoreApp.initialize().then(()=>{
            /*this.props.history.listen((location, mode)=>{
                //Page.set(location, mode)
                Tracker.trackPageView(location);
                setTimeout(() => { document.getElementById('root').scrollIntoView({ behavior: "smooth" }) }, 377)
            })*/
            this.setState({initialized: true});
        })

    }

    render() {
        return this.state.initialized && <Layout />
    }
}