export function getURLParameter(name, location) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [undefined, ""])[1].replace(/\+/g, '%20')) || null;
}

export function capitaliseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function generateGUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
    return uuid;
}

export function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Get random string
 * @param len
 * @returns {string}
 * @private
 */
export function randomString(len) {
    var str = '';
    len = len || 10
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < len; i++) {
        str += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return str;
}

export function versionCheck(params) {

}
/**
 * Load styleshet
 * @param src
 * @private
 */
export function loadStyleSheet(src=null) {
    if(typeof document === 'undefined') { return null; }

    var link = document.createElement('link');
    link.href = src;
    link.setAttribute('type', 'text/css');
    link.setAttribute('media', 'all');
    link.setAttribute('rel', 'stylesheet');
    document.getElementsByTagName('body')[0].append(link);
}