import React from "react";
import Api from "../../core/api";

export default class PackageList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.loadPackages()
    }

    loadPackages=()=>{
        let query = ''
        if(window.location && window.location.search) {
            let match = /(q=)(\w)+/g.exec(window.location.search)
            if(match) {
                query = match[0].replace('q=', '')
            }
        }
        let params = {}
        if(query) {
            params = {query};
        }
        Api.request('package/list', params).then(response=>{
            if(response.packages) {
                this.setState({packages: response.packages, query: query})
            }
        }, err=>{

        })
    }
    render() {
        let packages = this.state.packages || []
        if(!this.state.packages || this.state.packages.length === 0) {
            return this.state.query ? <div>No packages found matching '{this.state.query}'</div> : null
        }
        return packages.map(item=><div key={item.id} className="package-item">
            <div className="headding row">
                <h3 className="col-6"><a href={`/package/${item.namespace}`}>{item.namespace}</a> <span className="sub small">(v{item.version})</span></h3>
                <div className="col-5 info text-left">
                    <span className="version">v{item.version}}</span>
                    <span className="publisher">by <a href="#">{item.publisher_name}</a></span>
                </div>
            </div>
            <p className="description">
                {item.description}
            </p>
            <div className="footer"></div>
        </div>)
    }
}