

const siteConfig = {};

export class Tracker {

    history = null;

    initialize=()=>{
        this.initGoogleAnalytics();
        this.initFBPixel();
    }

    /**
     * Initialize GA analytics plugin
     */
    initGoogleAnalytics=()=>{
        if(typeof window === 'undefined' || !siteConfig.google_analytics_id) {
            return;
        }

        if(window.gtag) {
            this.gtag = window.gtag;
            return;
        }

        let elm = document.createElement('script');
        elm.async = true;
        elm.src = `https://www.googletagmanager.com/gtag/js?id=${siteConfig.google_analytics_id}`;
        document.getElementsByTagName('head')[0].appendChild(elm);

        window.dataLayer = window.dataLayer || [];
        function gtag(){
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', siteConfig.google_analytics_id);

        if(siteConfig.google_campaigns) {
            Object.keys(siteConfig.google_campaigns).forEach(code=>{
                gtag('config',siteConfig.google_campaigns[code].id)
            })
        }

        this.gtag = gtag;
    }

    /**
     * Initialize facebook pixel
     */
    initFBPixel=()=>{

        if(typeof window === 'undefined') {
            return;
        }

        if(window.fbq) {
            this.fbs = window.fbq;
            return;
        }

        this.fbq = window.fbq=function(){
            this.fbq.callMethod? this.fbq.callMethod.apply(this.fbq,arguments) : this.fbq.queue.push(arguments);
        };
        if(!window._fbq) {
            window._fbq=this.fbq;
        }
        this.fbq.push = this.fbq;
        this.fbq.loaded=!0;
        this.fbq.version='2.0';
        this.fbq.queue=[];

        let elm = document.createElement('script');
        elm.async = true;
        elm.src = 'https://connect.facebook.net/en_US/fbevents.js';
        document.getElementsByTagName('head')[0].appendChild(elm);
        elm.onload =()=> {
            this.fbq('init', siteConfig.facebook_pixel_id_campaign);
            this.fbq('track', 'PageView');
        }
    }

    /**
     * Initialize the history module to track page routing history thro react-router in the index.js initializer page
     * @returns {History}
     */
    initHistory=()=>{
        /*if(!this.history) {
            let History = require("history");
            this.history = History.createBrowserHistory();
            this.history.listen(this.trackPageView);
        }
        return this.history;*/
    }

    /**
     * @returns {History}
     */
    getHistoryTracker=()=>{
        if(!this.history) {
           return this.initHistory();
        }
    }

    /**
     * @param {object} location
     */
    trackPageView=(location)=>{
        //setting a timeout to record the page view since components might update the page title after it is mounted
        setTimeout(()=>{
            if(siteConfig.google_analytics_id) {
                this.gtag('config', siteConfig.google_analytics_id, {page_path: location.pathname, page_location: location.pathname + location.search});
            }

            this.fbq && this.fbq('track', 'PageView');
        }, 1000)

    }


    /**
     *
     * @param {string} action
     * @param {object} data
     */
    trackEvent=(action, data={})=>{
        console.log('Tracking '+ action, data)
        let gtagData = {};
        let fbData = {};
        let gAction,
            fbAction = action;

        if(data.value) {
            gtagData.value = data.value;
            fbData.value = data.value;
        }
        if(data.category) {
            gtagData.event_category = data.category;
        }
        if(data.label) {
            gtagData.event_label = data.label;
        }
        if(data.method) {
            gtagData.method= data.method;
        }
        if(data.non_interaction) {
            gtagData.non_interaction= true;
        }
        if(data.transaction_id) {
            gtagData.transaction_id= data.transaction_id;
            fbData.transaction_id= data.transaction_id;
        }
        if(data.currency) {
            gtagData.currency = data.currency;
            fbData.currency = data.currency;
        }
        if(data.send_to) {
            gtagData.send_to= data.send_to;
        }

        if(action === 'Purchase') {
            gAction = 'conversion';
        }
        console.log('Google event', gAction, gtagData);
        console.log('FB event', fbAction, fbData);
        this.gtag('event', gAction, gtagData);
        this.fbq('track', fbAction, fbData);
    }
}

export default new Tracker();