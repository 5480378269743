import React,  { useState } from "react";
import ModuleDetails from "./details";

export default (props)=>{

    const [activeMethod, setActiveMethod] = useState('');

    let modules = (props.modules || [])

    modules.forEach(m=>{
        if(!m.details || !m.details.ref) {
            m.sk = m.name.substr(0, 2)
        } else {
            let ref = m.details.ref.replace(props.pckg_namespace +'/', '')
            m.sk = ref.split('/').reduce((p,c)=>`${p}${c.substr(0, 2)}`,'')
        }
    })

    /*modules.sort((a,b)=>{
        let c = 0;
        if(!a.sk || !b.sk) { return c}

        if(a.sk.length === b.sk.length) {
            c = a.sk > b.sk ? 1 : -1;
        } else if(a.sk.substr(0,2) === b.sk.substr(0,2)) {
            c = a.sk.slice(2) > b.sk.slice(2) ? 1 : -1;
        } else {
            c = a.sk.length > b.sk.length ? 1 : -1;
        }
        return c
    })*/
    return modules.map((module, idx)=>{
        let ref = module.details && module.details.ref
        return <div key={idx}
                    id={module.sk}
                    onClick={e=>idx === activeMethod ? setActiveMethod('') : setActiveMethod(idx)}
                    className={`module-item border-bottom mt-2 ${activeMethod === idx ? 'open': ''}`}>
            <h4 className="name">
            <img className="icon-caret" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiB3aWR0aD0iMTc5MiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTE1MiA4OTZxMCAyNi0xOSA0NWwtNDQ4IDQ0OHEtMTkgMTktNDUgMTl0LTQ1LTE5LTE5LTQ1di04OTZxMC0yNiAxOS00NXQ0NS0xOSA0NSAxOWw0NDggNDQ4cTE5IDE5IDE5IDQ1eiIvPjwvc3ZnPg==" />
            {module.details && module.details.ref.replace(props.pckg_namespace +'/', '')}
            {!module.details && module.name}</h4>
            <span className="description">{module.description}</span>
            {module.details && <ModuleDetails details={module.details} />}
        </div>
    })
}
