import React from "react";

import Packages from "../package/list"
export default (props)=>{
    return (
        <div className="container">
            <h1 className="section-head">Codeflow Packages</h1>
            <Packages />
        </div>
    )
}