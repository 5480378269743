import React from "react";
import { Switch } from "react-router-dom";
import AppliedRoute from "./route/applied";
import AuthenticatedRoute from "./route/authenticated";
import UnauthenticatedRoute from "./route/unauthenticated";
import Home from "./home/index";
import PackageView from "./package/view";
import NotFound from "./route/notFound";

const routeConfigs = [{path: '/', component: Home},
                    //{path: 'login', component: 'user/Login'},
                    //{path: 'signup', component: 'user/Signup'},
                    //{path: 'tos', component: 'web/Tos'},
                    //{path: 'toc', component: 'web/Tos'},
                    //{path: 'package/list/:type', component: 'package/list'},
                    {path: 'package/:ns/:name', component: PackageView},

                    {path: 'notfound', component: NotFound},
                    {component: NotFound}
                ]

export function getRouteConfig(props) {

    return routeConfigs.map((route, index)=>{
        //if(route.path === '' || route.path === '/') { return null; }
        return {
            path: route.path ? `/${route.path}` : null,
            component: route.component, //require(componentPath).default,
            exact: true,
            key: index,
            auth: route.auth,
            unauth: route.unauth
        }
    })
}

export default (props)=><Switch>

            {getRouteConfig().map(routeConfig=>{
                if(routeConfig.auth) {
                    return <AuthenticatedRoute {...routeConfig} props={props} />
                } else if(routeConfig.unauth) {
                    return <UnauthenticatedRoute {...routeConfig} props={props}  />
                }
                return <AppliedRoute {...routeConfig} props={props} />
            })}
    </Switch>
