import React from "react";

const RenderSchema = (props)=>{
    const RenderType = (schema)=>{
        switch(schema.type) {
            case 'object': return RenderObject(schema)
            case 'array': return RenderArray(schema)
            case 'string': return RenderString(schema)
            case 'integer': return RenderInteger(schema)
            case 'boolean': return RenderBoolean(schema)
            case 'any': return RenderAny(schema)
            default: return null
        }
    }

    let {schema, name} = props
    schema = schema || {}
    return <dl className="schema-item">
        {name && <dt className="label">
            {name}
            {schema.required === true || schema.required === 'true' ? '*' : ''}
            &nbsp;<small>
                <b>&#123;</b>
                {schema.type}
                {schema.format==='resource' ? ':resource': ''}
                <b>}</b>
            </small>
        </dt>}

        <dd className="description">{schema.description}</dd>
        {typeof schema.default !== 'undefined' && schema.default != '' && <dd className="description">default: {schema.default}</dd>}
        {schema.format==='resource' && schema.resourceRef != '' && <dd className="resourceRef">Resource Type: {schema.resourceRef}</dd>}
        <dd className="properties"><RenderType {...schema}/></dd>
    </dl>
}


const RenderArray = (schema)=>{
    if(!schema.items) { return null; }
    return <RenderSchema schema={schema.items} />
}

const RenderObject = (schema)=>{
    if(!schema.properties) { return null; }
    return Object.keys(schema.properties).map(k=><RenderSchema key={k} name={k} schema={schema.properties[k]} />)
}

const RenderString = (schema)=>{
    return <div></div>
}

const RenderInteger = (schema)=>{
    return <div></div>
}

const RenderBoolean = (schema)=>{
    return <div></div>
}
const RenderAny = (schema)=>{
    return <div></div>
}

export default (props)=>{
    let details = props.details || {}
    let jsonString = JSON.stringify(details, '', 4)

    return <div className="module-details border-0">
        <div className="card card-body border-0">
            <div className="details">
                <div className="io">
                    {details.input && <div className="input">
                        <h6>Input
                            {details.input.type && <small className="smaller">
                                &nbsp;<b>&#123;</b>
                                {details.input.type}
                                <b>}</b>
                            </small>}
                        </h6>
                        <RenderSchema schema={details.input} name={null} />
                    </div>}
                    {details.output && <div className="output">
                        <h6>Output
                            {details.output.type && <small className="smaller">
                                &nbsp;<b>&#123;</b>
                                {details.input.type}
                                <b>}</b>
                            </small>}
                        </h6>
                        <RenderSchema schema={details.output} name={null} />
                    </div>}
                </div>
                <p className="description d-none" onClick={e=>console.log(e.target)} style={{whiteSpace: 'pre-wrap'}}>{jsonString}</p>
            </div>
        </div>
    </div>
}