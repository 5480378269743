export class Api {

    user_token = null;
    constructor(options={}) {
        this.RETRY_COUNT = 2;
        this.TIMEOUT = 600000; //60 secs
        this.NETWORK_ERROR = false;
        this.NETWORK_LAST_CHECKED = 0;


        //this.server = options.server || 'http://localhost:8010';
        this.server = options.server || window.location.origin;
        this.apiPrefix = `/${options.path || 'api'}/v1/`;
        this.user_token = options.token || null;
    }

    setToken(token) {
        this.user_token = token;
    }

    getUrl(path) {
        return `${this.server}${this.apiPrefix}${path}`;
    }

    showLoader(options={}) {
        if(options.show_loading) {

        }
        if(options.show_preloader || options.preloader_text) {

        }
    }

    hideLoader(options={}) {
        if(options.show_loading) {

        }
        if(options.show_preloader || options.preloader_text) {

        }
    }

    processResponseError(error) {
        //delete the user session on invalid token auth
        if(error.code === 'auth_error') {
            //logout user
        }
        return false;
    }

    /**
     * Make object transferable in formData
     * @param {*} obj
     * @param {*} formData
     * @param {*} namespace
     */
    objectToFormData(obj, formData = null, namespace = null) {

        formData = formData || new FormData();
        let formKey = '';

        for(var property in obj) {
            if(!obj.hasOwnProperty(property)) { return; }
            formKey = namespace ? `${namespace}[${property}]` : property;

            if (obj[property] instanceof Date) {
                formData.append(formKey, obj[property].toISOString());
            } else if (obj[property] instanceof Array) {
                obj[property].forEach((element, index) => {
                    this.objectToFormData(element, formData, `${formKey}[${index}]`);
                });
            } else if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                this.objectToFormData(obj[property], formData, formKey);
            } else { //file or non object
                formData.append(formKey, obj[property]);
            }
        }

        return formData;
    }

    request(path, params={}, method="GET", options={}) {
        if(typeof params === 'string') {
            method = params;
            params = {};
            if(typeof method === 'object') {
                options = method;
            }
        }
        let reqOptions = {
            method: method,
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
        }
        if(options.headers) {
            reqOptions.headers = options.headers;
        }

        if(!options.no_token && this.user_token) {
            params.token = this.user_token;
        }
        if(options.offline_first) {
            reqOptions.offline_first = true;
        }

        if(method === 'GET') {
            reqOptions.params = params;
        } else {
            if(params) {
                if(options.upload_file) {
                    reqOptions.method = 'POST';
                    delete(reqOptions.headers['Content-Type']);
                    reqOptions.body = params;
                    //assuming that the file is already added to the params as 'file' property
                    //Object.keys(params).forEach(key => reqOptions.body.append(key, params[key]))
                } else { // do a normal post request with the parameters
                    reqOptions.body = JSON.stringify(params);
                }
            }
        }
        return new Promise((resolve, reject)=>{
            this.makeRequest(this.getUrl(path), reqOptions).then(resolve, reject);
        })

    }

    async sendOfflineRequest(url, options) {
        url = typeof url === 'string' ? url : url.url;
        let _url = url.replace(`${this.server}${this.apiPrefix}`, '');
        let response = await window.OfflineApp.sendMessage({url: _url, options});
        return response || {};
    }

    async makeRequest(url, options={}, retryIdx = 0) {
        let isOfflineApp = typeof window !== 'undefined' && !!window.OfflineApp;
        return new Promise((resolve, reject)=>{

            this.showLoader(options);
            if(options.method === 'GET' && typeof url === 'string') {
                let urlParams = Object.keys(options.params).map(key => key + '=' + options.params[key]).join('&');
                url += (url.indexOf('?') > 0 ? '&' : '?')+ urlParams;
            }

            if((typeof navigator !== 'undefined' && !navigator.onLine) || (isOfflineApp && options.offline_first)) { //
                if(isOfflineApp) {
                    return this.sendOfflineRequest(url, options).then(resolve, reject);
                }
                if(!navigator.onLine) {
                    return reject('Network down')
                }
                //not required while creating a request object
                delete(options.offline_first);
            }
            let request = new Request(url, options);
            // get the response
            fetch(request.clone()).then(response=>{
                //reset network error
                if(this.NETWORK_ERROR) {
                    this.NETWORK_ERROR = false;
                    this.NETWORK_LAST_CHECKED = 0;
                }

                this.hideLoader(options);

                if (response.status !== 200 && !response.ok) {
                    return null;
                }
                if (response.error) {
                    this.processResponseError(response.error);
                }
                //parse response as json
                response.json().then(data=>resolve(data||{}), reject);

            }, err => {
                this.hideLoader(options);

                if(retryIdx >= this.RETRY_COUNT) {
                    this.NETWORK_ERROR = true;
                    this.NETWORK_LAST_CHECKED = new Date().getTime();
                    throw err;
                }

                retryIdx++;
                return this.makeRequest(request, options, retryIdx).then(resolve, reject);
            })
        })
    }

    upload(path, file,  params = {}, options={}) {
        if(!options.no_token && this.user_token) {
            params.token = this.user_token;
        }
        let formData = this.objectToFormData(params);
        formData.append('file', file, file.name);
        return this.request(path, formData, 'POST', {upload_file: true});
    }

}

export default new Api();